import React from "react";

const Loader = () => {
  return (
    <div className="Loader">
      <div className="Loader__line"></div>
      <p className="Loader__text">Loading...</p>
    </div>
  );
};

export default Loader;
