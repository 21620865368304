import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Home from "./containers/Home";
import Loader from "./components/Loader";
const Products = React.lazy(() => import("./containers/Products"));
const Services = React.lazy(() => import("./containers/Services"));
const FarmingAndManufacturing = React.lazy(
  () => import("./containers/FarmingAndManufacturing")
);
const OilAndGas = React.lazy(() => import("./containers/OilAndGas"));

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/" exact component={Home} />
        <Suspense fallback={<Loader />}>
          <Route path="/products" component={Products} />
          <Route path="/services" component={Services} />
          <Route path="/agriculture" component={FarmingAndManufacturing} />
          <Route path="/oil-and-gas" component={OilAndGas} />
        </Suspense>
        <Redirect to="/" />
      </Switch>
    </div>
  );
}

export default App;
