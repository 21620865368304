import React from "react";

const IntroOverlay = () => {
  return (
    <div className="IntroOverlay">
      <div className="IntroOverlay__item"></div>
      <div className="IntroOverlay__item"></div>
      <div className="IntroOverlay__item"></div>
    </div>
  );
};

export default IntroOverlay;
