import React, { lazy, Suspense, useEffect } from "react";
import { TimelineMax, gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import AppLayout from "../../layouts/App";
import IntroOverlay from "../../components/Overlay";
gsap.registerPlugin(ScrollTrigger);

const Overview = lazy(() => import("./Overview/"));
const About = lazy(() => import("./About"));
const Services = lazy(() => import("./Services"));
const Mantra = lazy(() => import("./Mantra"));
const Contact = lazy(() => import("../../components/Contact"));
const KjmLogo = lazy(() => import("../../components/KjmLogo"));

const Home = (): JSX.Element => {
  useEffect(() => {
    gsap.to("body", { css: { visibility: "visible" } });

    return;
  }, []);

  useEffect(() => {
    const tl = new TimelineMax();
    tl.from(".Home__heading", {
      delay: 0.5,
      duration: 1.5,
      opacity: 0,
      y: 30,
      stagger: 0.4,
    })
      .to(".IntroOverlay__item", {
        delay: 0.8,
        duration: 1.6,
        height: 0,
        ease: "expo.inOut",
        stagger: 0.4,
      })
      .to(".IntroOverlay", { duration: 0, css: { display: "none" } });

    return;
  }, []);

  return (
    <AppLayout isNavbarTransparent={true}>
      <div className="Home">
        <header className="Home__header">
          <IntroOverlay />
          <div className="Home__heading-box">
            <h1 className="Home__heading">Trade Facilitation</h1>
            <h1 className="Home__heading">Commodity Import &amp; Export</h1>
            <h1 className="Home__heading">
              Upstream &amp; Downstream Petroleum Services
            </h1>
            <h1 className="Home__heading">Logistics Services</h1>
          </div>
          <video
            className="Home__header-video"
            playsInline={true}
            autoPlay={true}
            muted={true}
            loop={true}
            id="bgvid"
            src="https://res.cloudinary.com/xoladigital/video/upload/f_auto,q_auto:low/v1601661789/kjmtrading/homepage/Untitled_5-1_q7cx4p.mp4"
          />
          <div className="Home__header-overlay"></div>
        </header>
        <Suspense fallback={<h1>Loading...</h1>}>
          <Overview />
        </Suspense>

        <Suspense fallback={<h1>Loading...</h1>}>
          <About />
        </Suspense>

        <Suspense fallback={<h1>Loading...</h1>}>
          <Services />
        </Suspense>

        <Suspense fallback={<h1>Loading...</h1>}>
          <Mantra />
        </Suspense>

        <div className="Home__divider"></div>
        <Suspense fallback={<h1>Loading...</h1>}>
          <Contact />
        </Suspense>

        <Suspense fallback={<h1>Loading...</h1>}>
          <KjmLogo />
        </Suspense>
      </div>
    </AppLayout>
  );
};

export default Home;
