import React from "react";

interface ICloseIconProps {
  color: string;
  changeColor: boolean
}

const CloseIcon = (props: ICloseIconProps): JSX.Element => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
      <path
        d="M18.4842 6.48419L6.48419 18.4842"
        stroke={props.changeColor ? props.color : 'black'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.48419 6.48419L18.4842 18.4842"
        stroke={props.changeColor ? 'white' : 'black'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CloseIcon;
