import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import { Link } from "react-scroll";
// import { TimelineMax } from "gsap";

import MenuIcon from "../../components/Icons/MenuIcon";
import CloseIcon from "../../components/Icons/CloseIcon";
import Footer from "../../components/Footer";

import KjmLogo from "../../assets/kjmlogo.png";
import KjmLogoColored from "../../assets/kjmlogo-colored.png";

interface IAppLayoutProps {
  children: React.ReactNode;
  isNavbarTransparent: boolean;
}

const AppLayout = (props: IAppLayoutProps): JSX.Element => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [navbar, setNavbar] = useState(false);

  const handleMenuOpen = (): void => {
    setMenuOpen(!isMenuOpen);
  };

  const changeNavbarBackground = () => {
    if (window.scrollY > 178) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNavbarBackground);

    return function cleanupListener() {
      window.removeEventListener("scroll", changeNavbarBackground);
    };
  }, []);

  const MobileNavigation = (): JSX.Element => {
    return (
      <ul className="MobileNavigation">
        <li className="MobileNavigation__link-item">
          <Link
            to="/products"
            className="MobileNavigation__link"
            onClick={handleMenuOpen}
          >
            Products
          </Link>
        </li>
        <li className="MobileNavigation__link-item">
          <Link
            to="/services"
            className="MobileNavigation__link"
            onClick={handleMenuOpen}
          >
            Services
          </Link>
        </li>
        <li className="MobileNavigation__link-item">
          <Link
            to="/agriculture"
            className="MobileNavigation__link"
            onClick={handleMenuOpen}
          >
            Agriculture
          </Link>
        </li>
        <li className="MobileNavigation__link-item">
          <Link
            to="/oil-and-gas"
            className="MobileNavigation__link"
            onClick={handleMenuOpen}
          >
            Oil &amp; Gas
          </Link>
        </li>

        <li className="MobileNavigation__link-item">
          <Link
            to="#contacts"
            className="MobileNavigation__link"
            onClick={handleMenuOpen}
          >
            Contact Us
          </Link>
        </li>
      </ul>
    );
  };

  return (
    <div className={`AppLayout`}>
      <div
        className={`${
          props.isNavbarTransparent && navbar
            ? "AppLayout__header AppLayout__header--active"
            : props.isNavbarTransparent
            ? "AppLayout__header AppLayout__header--no-background"
            : "AppLayout__header"
        }`}
      >
        <nav className={`Navigation`}>
          <div className="Navigation__logo-box">
            <Link to="/">
              <img
                src={`${props.isNavbarTransparent ? KjmLogo : KjmLogoColored}`}
                alt="Kjm trading"
                className="Navigation__logo"
              />
            </Link>
          </div>

          <div className="Navigation__menu-icon" onClick={handleMenuOpen}>
            {isMenuOpen ? (
              <CloseIcon
                color="white"
                changeColor={
                  props.isNavbarTransparent === false
                    ? false
                    : navbar || props.isNavbarTransparent
                }
              />
            ) : (
              <MenuIcon
                color="white"
                changeColor={
                  props.isNavbarTransparent === false
                    ? false
                    : navbar || props.isNavbarTransparent
                }
              />
            )}
          </div>
          <ul
            className={`${
              props.isNavbarTransparent
                ? "Navigation__links Navigation__links--no-background"
                : "Navigation__links"
            } `}
          >
            <li className="Navigation__link-item">
              <Link className="Navigation__link" to="/products">
                Products
              </Link>
            </li>
            <li className="Navigation__link-item">
              <Link className="Navigation__link" to="/services">
                Services
              </Link>
            </li>
            <li className="Navigation__link-item">
              <Link className="Navigation__link" to="/agriculture">
                Agriculture
              </Link>
            </li>
            <li className="Navigation__link-item">
              <Link className="Navigation__link" to="/oil-and-gas">
                Oil &amp; Gas
              </Link>
            </li>
            <li className="Navigation__link-item">
              <a className="Navigation__link" href="#contact">
                Contact Us
              </a>
            </li>
          </ul>
          {isMenuOpen && <MobileNavigation />}
        </nav>
      </div>
      <div className="AppLayout__body">
        {props.children}
        <Footer />
      </div>
    </div>
  );
};

export default AppLayout;
