import React from "react";

interface IMenuIconProps {
  color: string;
  changeColor: boolean;
}

const MenuIcon = (props: IMenuIconProps): JSX.Element => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className="menuIcon"
    >
      <path
        d="M3 12H21"
        stroke={props.changeColor ? props.color : "black"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 6H21"
        stroke={props.changeColor ? props.color : "black"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 18H21"
        stroke={props.changeColor ? props.color : "black"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MenuIcon;
